<template>
  <form @submit.prevent="handleSubmit" class="modal-content">
    <!--    <pre>{{ availableParents }}</pre>-->
    <div class="modal-header p-25">
      <h5 class="modal-title" style="flex-grow: 1">
        <input
          :placeholder="`${$t('message.enterATitle')}...`"
          v-model="title"
          type="text"
          class="input-xl w-100"
        />
      </h5>
      <button
        tabindex="-1"
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="el mb-3">
        <label for="" class="text-sentence">{{ $t("message.description") }}</label>
        <textarea
          class="w-100"
          v-model="body"
          name=""
          id=""
          cols="30"
          rows="5"
        ></textarea>
      </div>

      <div v-if="!team.issegment" class="el mb-3 d-flex align-items-center">
        <checkbox v-model="isbase"></checkbox>
        <label class="mb-0 text-sentence" for="">{{ $t("message.thisIsAMainBranch") }}</label>
      </div>
      <div class="el mb-3" v-if="isbase">
        <label for="">{{ $t('message.position') }}</label>
        <input v-model="position" type="number" min="1">
      </div>
      <div
        class="el mb-3"
        v-if="team.issegment !== true && team.isbase !== true && !isbase"
      >
        <label for="" class="text-sentence">{{ $t("message.parentTeams") }}</label>
        <select-search
          class="mb-2"
          :ajax="true"
          :query="parentQuery"
          :label="'title'"
          :value="'id'"
          @selectOption="addParent"
        ></select-search>
        <div
          class="mb-2 d-flex justify-content-between"
          v-bind:key="parentteam.id"
          v-for="(parentteam, ind) in parents"
        >
          <div class="title">{{ parentteam.title }}</div>
          <button
            type="button"
            @click.prevent="removeParent(parentteam, ind)"
            class="p-0 btn btn-link"
          >
            <img src="../../../assets/icons/icon-navigation-close.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between p-25">
      <button
        v-if="!loading"
        type="button"
        class="btn btn-link text-danger ms-0"
        data-bs-dismiss="modal"
        @click="$emit('deleteTeam', team)"
      >
        <span class="text-sentence">{{ $t("message.delete") }}</span>
      </button>
      <div>
        <button
          v-if="!loading"
          type="button"
          class="btn btn-secondary me-2"
          data-bs-dismiss="modal"
        >
          <span class="text-sentence">{{ $t("message.cancel") }}</span>
        </button>
        <button v-if="!loading" type="submit" class="btn btn-primary">
          <span class="text-sentence">{{ $t("message.save") }}</span>
        </button>
        <button v-else class="btn disabled d-flex" type="button" disabled>
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{ $t("message.loading") }}</span>
          </div>
          <span class="ms-2 text-sentence">{{ $t("message.submitting") }}</span>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import SelectSearch from "@/views/components/simple/SelectSearch";
import Checkbox from "@/views/components/simple/Checkbox";
// import qs from 'qs'

export default {
  components: { Checkbox, SelectSearch },
  emits: ["submitTeam", "deleteTeam"],
  data() {
    return {
      loading: false,
      selectVal: "",
      selected: "",
      isbase: this.team.isbase,
      title: this.team.title,
      body: this.team.body,
      parents: [],
      availableParents: [],
      position: this.team.position || 1,
    };
  },
  computed: {
    parentQuery() {
      let q = "/swarms";
      if (this.team.id) {
        q += "?children_ne=" + this.team.id;

        // q = '/swarms?' + qs.stringify({
        //   children: {
        //     _where:
        //         {
        //           _or: [
        //             {
        //               id_ne: this.team.id,
        //             },
        //             {
        //               'id_null': true,
        //             }
        //           ]
        //         }
        //   }
        // })
      }
      if (this.parentIds.length > 0) {
        q += (q.indexOf("?") > -1 ? "&" : "?") + "id_nin=" + this.parentIds;
      }
      return q;
    },
    parentIds() {
      return this.parents.map((parent) => {
        if (parent) {
          return parent.id;
        }
        return 0;
      });
    },
  },
  mounted() {
    this.getAvailableParents();
    if (this.team && this.team.id && this.team.isbase !== true) {
      this.getTeamParents();
    }
  },
  props: {
    team: Object,
  },
  methods: {
    removeParent(parent, ind) {
      this.parents.splice(ind, 1);
      this.availableParents.push(parent);
    },
    async getAvailableParents() {
      if (this.team.issegment) return;
      let q = "";
      if (this.team.id) {
        q = "?_limit=-1&children_ne=" + this.team.id;
      }
      const get = await this.axios.get("/swarms" + q);
      this.availableParents = get.data;
    },
    addParent(e) {
      // console.log('adding parent', e)
      //e.target.value
      const id = e.id;
      this.selectVal = "";
      // console.log("available", this.availableParents);
      this.selected = this.availableParents.filter((parent) => {
        return parent.id === parseInt(id);
      })[0];
      // console.log('selected', this.selected)
      this.parents.push(this.selected);
      this.availableParents = this.availableParents.filter((parent) => {
        return parent.id !== this.selected.id;
      });
    },
    async getTeamParents() {
      const get = await this.axios.get(
        "/swarms?children_contains=" + this.team.id
      );
      this.parents = get.data;
    },
    async handleSubmit() {
      this.loading = true;
      const team = {
        // hasbases: this.team.hasbases,
        issegment: this.team.issegment === true,
        title: this.title,
        body: this.body,
        isbase: this.isbase,
        parents: this.team.issegment === true ? [] : this.parents,
        position: this.position,
      };
      // if (team.parents.length < 1 && this.team.isbase !== true && this.team.issegment !== true) {
      //   const getFirstBase = await this.axios.get('/swarms?isbase=true&_limit=1')
      //   const base = getFirstBase.data
      //   team.parents = base;
      // }
      let emitData;
      if (this.team && this.team.id) {
        const put = await this.axios.put("/swarms/" + this.team.id, team);
        emitData = put.data;
      } else {
        const post = await this.axios.post("/swarms/", team);
        emitData = post.data;
      }
      this.$emit("submitTeam", emitData);
      // this.$emit("setTree");
      // this.$emit("reloadTree");
      // console.log("emit");
      this.loading = false;
      this.$store.dispatch("closeModal");
    },
  },
};
</script>