<template>
  <div
    class="team-row team team-item"
    :class="team.issegment ? ' item-segment' : ' item-team'"
  >
    <!--    <pre>type:{{type}}</pre>-->
    <div
      class="team-content d-flex border-top cursor-pointer"
      :id="'heading-team-' + team.id"
      @click.stop="setActiveTeam(team)"
    >
      <div class="team-name flex-grow-1 d-flex align-items-center">
        <div class="d-inline-block me-2" v-if="type === 'segment' || type === 'teams'">
          <checkbox v-model="checked" />
        </div>
        <span class="flex-grow-1">
          <img
            :class="'c-200 bgc-' + team.color"
            style="border-radius: 4px"
            v-if="team.issegment"
            class="me-3"
            :src="
              require(`@/assets/icons/Icon-block-segment-24px-transparent.svg`)
            "
            alt=""
          />
          <span
            :class="
              team.isbase != true && team.issegment != true ? 'handle' : ''
            "
          >
            {{ team.title }}
          </span>
        </span>
        <span v-if="team.isbase" class="ms-auto me-2 tip tip-base">
          <span class="text-sm tip-content text-sentence">
            {{ $t("message.base_team_tooltip") }}
          </span>
          <i class="c-okergeel c-200 fas fa-fw fa-star"></i>
        </span>
        <span
          v-if="!team.isbase && team.parentcount < 1 && !team.issegment"
          class="ms-auto me-2 tip tip-orphan"
        >
          <span class="text-sm tip-content text-sentence">
            {{ $t("message.orphan_team_tooltip") }}
          </span>
          <i class="c-koraalrood c-200 fas fa-fw fa-exclamation-triangle"></i>
        </span>
        <button
          @click.stop="toggleChildren"
          type="button"
          v-if="team.children && team.childCount > 0 && type !== 'segment'"
          class="btn btn-link p-0 collapse-toggler"
        >
          <div v-show="team.childrenLoading">
            <div class="spinner-border mx-1" role="status" style="width: 12px; height: 12px;">
                <span class="visually-hidden"
                >{{ $t("message.loading") }}...</span
                >
            </div>
          </div>
          <div v-show="!team.childrenLoading">
            <span class="expand-team">
              <img src="../../assets/icons/icon-navigation-expand.svg" alt="" />
            </span>
              <span class="collapse-team">
              <img src="../../assets/icons/icon-navigation-expand.svg" alt="" />
            </span>
          </div>
        </button>
      </div>
      <!--      <div class="notes"  v-if="team.isbase || team.parentcount < 1 && team.issegment !== true">-->
      <!--        <div class="tree-action text-center">-->

      <!--          <span v-if="!team.isbase && team.parentcount < 1" class="tip tip-orphan">-->
      <!--             <span class="text-sm tip-content">-->
      <!--              {{ $t('message.orphan_team_tooltip') }}-->
      <!--            </span>-->
      <!--             <i class="c-koraalrood c-200 fas fa-fw fa-exclamation-triangle"></i>-->
      <!--          </span>-->

      <!--        </div>-->
      <!--      </div>-->
      <div class="actions d-flex">
        <div
          class="
            d-none d-md-flex
            align-items-center
            tree-action
            users
            border-end
          "
          style="white-space: nowrap; width: 7ch"
        >
          <img src="../../assets/icons/Icon-tree-user-profile.svg" alt="" />
          <div class="text-end">{{ team.zerglingcount || 0 }}</div>
        </div>
        <div
          v-if="team.showStats && statsVisible"
          @click="showAnalyticsPopup"
          class="d-none d-md-block tree-action graph border-end"
        >
          <img src="../../assets/icons/Icon-tree-team-lifecycle.svg" alt="" />
        </div>
        <div
          style="font-size: 16px"
          v-else-if="statsVisible"
          class="
            d-none d-md-block
            text-center
            tree-action
            graph
            border-end
            pt-1
            d-flex
            align-items-center
            justify-content-center
          "

        >
          <i class="fa-fw fas fa-do-not-enter c-koraalrood c-200"></i>
          <!--          <img src="../../assets/icons/Icon-tree-team-lifecycle-grey.svg" alt="">-->
        </div>
        <div
          v-if="hasEditPermission"
          class="d-none d-md-block tree-action settings border-end"
          @click="showTeamPopup"
        >
          <img src="../../assets/icons/Icon-tree-company-settings.svg" alt="" />
        </div>
        <div
          v-else
          class="d-none d-md-block tree-action settings border-end"
        ></div>
        <div
          class="tree-action managers"
          @click.stop="setActiveTeamManagers(team)"
        >
          <img
            src="../../assets/icons/Icon-tree-manager-profile.svg"
            alt=""
          />{{ team.overlordcount || 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamModal from "@/views/components/modals/TeamModal";
import SegmentModal from "@/views/components/modals/SegmentModal";
import SwarmCheckupsModal from "@/views/components/modals/SwarmCheckupsModal";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import Checkbox from "@/views/components/simple/Checkbox";
import _ from 'lodash';
// import axios from "axios";

export default {
  components: { Checkbox },
  emits: [
    "toggleChildren",
    "setTree",
    "setActiveTeam",
    "setActiveTeamManagers",
    "reloadTree",
    "selectTeam",
    "loadChildren",
    "openChildren"
  ],
  methods: {
    refresh() {
      // console.log("reloadTree");
      console.log(this.team);
      this.$emit("reloadTree");
    },
    async confirmDelete(team) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: { id: team.id, title: team.title },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.deleteSegments,
        }
      });
    },
    async deleteSegments(id) {
      this.$emit("setLoaderFalse");
      await this.axios
        .put("/swarms/" + id, {
          visibility: false,
          title: this.team.title + ' [Deleted]'
        })
      if (this.team.issegment) {
        this.$emit("getSegments");
      } else {
        this.$emit("reloadAll", this.team);
        this.$emit("setTree", this.team);
      }
    },
    async toggleChildren() {
      if (!this.team.loadedChildren) {
        this.team.childrenLoading = true;
        this.mitt.emit('loadChildren', this.team);
      }
      this.$emit("toggleChildren", this.$el);
    },
    handleSubmitModal(team) {
      // console.log("submitTeam");
      if (!team.issegment && _.isEqual(this.team, team)) {
        return;
      }
      this.$emit("setTree", team);
    },
    showAnalyticsPopup() {
      this.$store.dispatch("openModal", {
        component: SwarmCheckupsModal,
        properties: { swarm: this.team, type: this.type },
        options: {
          size: "xl",
          centered: false,
        },
      });
    },
    showTeamPopup() {
      this.$store.dispatch("openModal", {
        component: this.$route.name === "tree" ? TeamModal : SegmentModal,
        events: {
          reloadTree: this.refresh,
          submitTeam: this.handleSubmitModal,
          submitSegment: this.handleSubmitModal,
          deleteTeam: this.confirmDelete,
          // setTree: this.refresh,
        },
        properties:
          this.$route.name === "tree"
            ? { team: this.team }
            : { segment: this.team },
      });
    },
    setActiveTeam(team) {
      this.$emit("setActiveTeam", team);
    },
    setActiveTeamManagers(team) {
      this.$emit("setActiveTeamManagers", team);
    },
  },
  computed: {
    hasEditPermission() {
      return (
        this.user.manageteams ||
        this.user.managecompany ||
        (this.team.issegment &&
          this.team.overlords &&
          this.team.overlords.map((entity) => entity.id).includes(this.user.id))
      );
    },
  },
  // computed: {
  //   activeTeamId() {
  //     const t = this.$store.getters.getActiveTreeTeam
  //     return t ? t.id : null;
  //   }
  // },
  data() {
    return {
      showChildren: false,
      team: this.content,
      checked: this.selected,
    };
  },
  watch: {
    checked() {
      this.$emit('selectTeam', {
        id: this.team.id,
        checked: this.checked,
      })
    },
    selected(val) {
      this.checked = val;
    }
  },
  mounted() {
    this.team.childrenLoading = false;
    this.mitt.on('loadedChildren', (id) => {
      if (id == this.team.id) {
        // console.log('correct team')
        this.team.childrenLoading = false;
        this.$emit("openChildren", this.$el)
        this.team.loadedChildren = true;
      }
    })
    this.mitt.on(`updatedSwarm-${this.team.id}`, (swarm) => {
      // console.log("updating", swarm)
      this.team = swarm;
    })
  },
  beforeUnmount() {
    this.mitt.off('loadedChildren');
    this.mitt.off(`updatedSwarm-${this.team.id}`)
  },
  // props: ["content", "index", "type", "selected", "statsVisible"]
  props: {
    content: {},
    index: {},
    type: {},
    selected: {},
    statsVisible: {
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style lang="scss" scoped>
.collapsing {
  transition: none !important;
}

.parent-hide-children .team-item {
  > .team-content .collapse-toggler {
    transform: rotate(-90deg);
  }
}

.collapse-toggler {
  transform: rotate(0deg);
  transition: transform 0.2s;

  &.collapsed {
    transform: rotate(-90deg);
  }
}

.handle {
  user-select: none;
}
</style>