<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div class="p-3 border-bottom-0 pb-0 modal-header bg-white">
      <button
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 justify-content-between d-flex">
          <h4>
            <span v-if="checkupListType === 'team' || checkupListType === 'segment'">
              {{ swarm.title }}
            </span>
            <span v-else-if="checkupListType === 'company'" class="text-sentence">
              {{ $t("message.company") }}
            </span>
            <span v-else class="text-sentence"> {{ $t("message.myTeams") }} </span>
            <!--            checkups-->
          </h4>
          <div
            class="mb-4 top d-flex align-items-center"
            v-if="
              swarm.issegment &&
              swarm.zerglingcount >= publicSettings.minimumPopulation
            "
          >
            <select
              class="text-capitalize mx-2 flex-grow-1"
              v-model="segmentAnalyticsType"
              name=""
              id=""
            >
              <option value="standard">{{ $t("message.standard") }}</option>
              <option value="descriptives">
                {{ $t("message.descriptives") }}
              </option>
              <option value="histograms">{{ $t("message.histogram") }}</option>
              <option value="ria">{{ $t("message.ria") }}</option>
              <option value="network">{{ $t("message.network") }}</option>
            </select>
          </div>
        </div>
        <div class="row" v-if="checkupsloaded">
          <div class="col-4" v-if="segmentAnalyticsType === 'standard'">
            <!-- todo: change to all my/company teams if list type changes -->
            <div class="mb-4 top d-flex align-items-center">
              <search
                :always-open="swarm.issegment"
                @search="handleSearch"
                class="w-auto"
              ></search>
              <select
                @change="getSwarmCheckups"
                v-if="!swarm.issegment"
                class="mx-2 flex-grow-1"
                v-model="checkupListType"
                name=""
                id=""
              >
                <option value="team">{{ swarm.title }}</option>
                <option value="manager">{{ $t("message.allMyTeams") }}</option>
                <option value="company" v-if="user.managecompany">
                  {{ $t("message.allCompanyTeams") }}
                </option>
              </select>
              <!--            <div class="flex-shrink-0 w-auto count">-->
              <!--              <img src="../../../assets/icons/Icon-tree-user-profile.svg" alt="">&nbsp;{{ selectedUserCount }}-->
              <!--            </div>-->
            </div>
            <div class="checkups-list checkboxes" v-if="checkupsloaded">
              <div
                :class="index < checkups.length - 1 && 'mb-4'"
                :style="
                  checkup.isOpen
                    ? 'box-shadow: inset 1px 3px 10px #0002!important;border:1px solid #0001'
                    : ''
                "
                class="card rounded-8"
                v-for="(checkup, index) in checkups"
                :key="checkup"
              >
                <div
                  class="card-header c-50 pe-2"
                  :class="'bgc-' + checkup.checkup.color"
                >
                  <div class="overline text-sm"><span class="text-sentence">{{ type === 'segment' ? $t("message.processed_on") : $t("message.ended_on") }} {{ moment(checkup.end).format('DD/MM/YYYY') }}</span></div>
                </div>
                <div class="card-body">
                  <div
                    @click.stop.prevent="checkup.isOpen = !checkup.isOpen"
                    class="row"
                    :class="checkup.isOpen && 'mb-3'"
                    style="cursor: pointer"
                  >
                    <div class="col">
                      <h5>{{ checkup.checkup.title }}</h5>
                      <p>
                        <img
                          src="../../../assets/icons/Icon-sidebar-tree.svg"
                          alt=""
                          class="me-1"
                        />
                        <span class="text-sentence">{{ checkup.swarmcomputeds.length }} {{ $t("message.teams") }} /
                        {{
                          checkup.swarmcomputeds.filter((item) => item.included).length
                        }}
                        {{ $t("message.selected") }}</span>
                      </p>
                    </div>
                    <div class="col-auto text-end">
                      <span v-show="checkup.isOpen"
                        ><i class="fas fa-chevron-down"></i
                      ></span>
                      <span v-show="!checkup.isOpen"
                        ><i class="fas fa-chevron-right"></i
                      ></span>
                    </div>
                  </div>
                  <input
                    v-model="checkup.activeSearch"
                    v-show="checkup.swarmcomputeds.length > 1 && checkup.isOpen"
                    type="text"
                    name=""
                    id=""
                    class="input input-lg mb-3 w-50 ms-auto"
                    :placeholder="$t('message.search')"
                  />
                  <div class="checkup-subgroup" v-show="checkup.isOpen">
                    <div
                      :class="computed.included && 'bgc-theme c-200 rounded-8'"
                      class="
                        row
                        mx-0
                        py-3
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                      v-for="computed in filteredChildren(checkup)"
                      :key="computed.id"
                    >
                      <div class="col-auto row d-flex align-items-center">
                        <div class="col-auto">
                          <checkbox v-model="computed.included" />
                        </div>
                        <div class="col-auto">
                          <progress-chart
                            style="height: 38px; width: 38px"
                            v-if="checkup.isOpen"
                            class=""
                            :border-width="2"
                            :f-size="'sm'"
                            :color="computed.color"
                            :pct="
                              (computed.users.participation /
                                computed.users.users.length) *
                              100
                            "
                          ></progress-chart>
                        </div>
                      </div>
                      <div class="col">
                        <h5 class="mb-0">{{ computed.title }}</h5>
                      </div>
                      <div class="col">
                        <div
                          class="text-sm mb-2"
                          v-for="swarm in computed.swarms"
                          :key="swarm.id"
                        >
                          <img
                            v-if="swarm.type == 'team'"
                            class="me-1"
                            src="../../../assets/icons/Icon-sidebar-tree.svg"
                            alt=""
                          />
                          <img
                            v-else
                            class="me-1"
                            src="../../../assets/icons/Icon-sidebar-segments.svg"
                            alt=""
                          />
                          {{ swarm.title }}
                        </div>
                        <div class="ms-auto text-sm text-end">
                          <span class="ms-auto">
                            <img
                              class="me-1"
                              src="../../../assets/icons/Icon-sidebar-Users.svg"
                              alt=""
                            />
                            &nbsp;{{ computed.users.users.length ?? "0" }}</span
                          >
                          <!--<span>
                            <img class="me-1" src="../../../assets/icons/Icon-tree-manager-profile.svg" alt="">
                            &nbsp;{{ computed.users.managers.length ?? '0' }}</span>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="checkup-item align-items-center row"
                   v-for="(checkup,index) in (this.searching ? filteredCheckups : checkups)" v-bind:key="checkup">
                <div class="check col-auto">
                  &lt;!&ndash;                                <pre>{{checkup}}</pre>&ndash;&gt;
                  <checkbox v-model="checkup.included"></checkbox>
                </div>
                <div class="align-items-center py-3 content d-flex col"
                     :class="(index > 0 ? 'border-top' : '')+' '+(checkup.included ? 'bgc-theme c-200' : '')">
                  <div class="col-auto pe-2">
                    <img :src="require(`@/assets/icons/Icon-block-checkup-32px-${checkup.color}.svg`)" alt="">
                  </div>
                  <div class="col">
                    <h5 class="mb-0">{{ checkup.title }}</h5>
                    <div v-if="checkup && checkup.title" class="d-flex align-items-center text-sm ">
                      <img class="me-1" style="max-width: 16px;" src="../../../assets/icons/Icon-block-team-32px.svg"
                           alt="">
                      {{ checkup.title }}
                    </div>
                    <div v-if="checkup && checkup.users" class="d-flex align-items-center text-sm ">
                      <img class="me-1" style="max-width: 16px;" src="../../../assets/icons/Icon-tree-user-profile.svg"
                           alt="">
                      {{ checkup.users.participation }}/{{ checkup.users.users.length }}
                      ({{ Math.round(checkup.users.participation / checkup.users.users.length * 100) }}%)
                    </div>
                    <div class="text-sm text-muted">
                      Ended on {{ moment(checkup.checkupentry.end).format('DD/MM/YYYY') }}
                    </div>
                  </div>
                </div>
              </div>-->
            </div>
            <div
              v-else
              class="
                position-absolute
                top-50
                start-50
                translate-middle
                container-spinner
              "
            >
              <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ $t("message.loading") }}...</span>
              </div>
            </div>
            <div class="text-sentence" v-if="checkups.length < 1">
              <span class="text-sentence">{{ $t("message.no_data_available") }}.</span>
            </div>
          </div>
          <div
            :class="type === 'teams' ? 'col-8' : 'col'"
            v-if="
              activeCheckups &&
              activeCheckups.length > 0 &&
              segmentAnalyticsType === 'standard'"
          >
            <!--          <pre>-->
            <!--            {{activeCheckups}}-->
            <!--          </pre>-->
            <!--          <h5>{{ activeCheckupTitles.join(', ') }}</h5>-->
            <checkup-analytics
              v-if="segmentAnalyticsType === 'standard'"
              ref="analytics"
              :computeds="activeCheckups"
            ></checkup-analytics>
          </div>
          <div
            class="col-12"
            v-if="swarm.issegment && segmentAnalyticsType !== 'standard'"
          >
            <descriptives
              :pop="swarm"
              v-if="segmentAnalyticsType === 'descriptives'"
            ></descriptives>
            <histograms
              :pop="swarm"
              v-if="segmentAnalyticsType === 'histograms'"
            ></histograms>
            <ria :pop="swarm" v-if="segmentAnalyticsType === 'ria'"></ria>
            <network-map
              :pop="swarm"
              v-if="segmentAnalyticsType === 'network'"
            ></network-map>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ $t("message.loading") }}...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Search from "@/views/components/simple/Search";
import CheckupAnalytics from "@/views/components/CheckupAnalytics";
import Checkbox from "@/views/components/simple/Checkbox";
import moment from "moment";
import Descriptives from "@/views/components/analytics/Descriptives";
import Histograms from "@/views/components/analytics/Histograms";
import Ria from "@/views/components/analytics/Ria";
import NetworkMap from "@/views/components/charts/NetworkMap";
import ProgressChart from "@/views/components/ProgressChart";
// import _ from "lodash";

export default {
  components: {
    NetworkMap,
    Ria,
    Histograms,
    Descriptives,
    Checkbox,
    CheckupAnalytics,
    Search,
    ProgressChart,
  },
  props: ["swarm", "type"],
  data() {
    return {
      segmentAnalyticsType: "standard",
      checkupsloaded: false,
      // activeCheckups: [],
      checkupListType: "team",
      checkups: [],
      search: "",
      searching: false,
      moment: moment,
    };
  },
  computed: {
    publicSettings() {
      return this.$store.getters.getPublicSettings;
    },
    selectedUserCount() {
      const includedCheckups = this.checkups.filter((checkup) => {
        return checkup.included;
      });

      let uids = [];
      includedCheckups.forEach((checkup) => {
        uids = uids.concat(checkup.users.users);
      });

      const uniqueUids = uids.filter((uid, index, self) => {
        return self.indexOf(uid) === index;
      });

      return uniqueUids.length;
    },
    filteredCheckups() {
      return this.checkups.filter((checkup) => {
        return (
          checkup.title.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      });
    },
    activeCheckups() {
      let active = [];
      for (const check of this.checkups) {
        active.push(...check.swarmcomputeds.filter((child) => child.included));
      }
      //console.log(active);
      return active;
      // return this.checkups.filter(checkup => {
      //   return checkup.included
      // })
    },
    activeCheckupTitles() {
      return this.activeCheckups.map((checkup) => {
        return checkup.title;
      });
    },
  },
  async mounted() {
    if (this.type === 'segment') {
      this.checkupListType = 'segment'
    }
    await this.getSwarmCheckups();
  },
  methods: {
    filteredChildren(checkup) {
      return checkup.swarmcomputeds.filter((child) =>
        child.title.toLowerCase().includes(checkup.activeSearch.toLowerCase())
      );
    },
    handleSearch(val) {
      this.search = val;
      if (this.search.length > 0) {
        this.searching = true;
      } else {
        this.searching = false;
      }
    },
    async getSwarmCheckups() {
      this.checkupsloaded = false;
      let q = "";
      switch (this.checkupListType) {
        case "manager":
          q = "/checkupentries/computed?own=true"; //list my managed team ids
          break;
        case "company":
          q = "/checkupentries/computed";
          break;
        case "team":
          q = "/checkupentries/computed?swarmcomputeds.swarm=" + this.swarm.id; // /checkups/completed?_sort=start:asc') //to get for specific teams: &checkup.swarms_contains=3
          break;
        case "segment":
          q = `/swarmcomputeds?swarm=${this.swarm.id}&_sort=updated_at:desc&_limit=1`
          break;
        default:
          q = "/checkupentries/computed";
      }

      const get = await this.axios.get(q);
      // console.log(get.data);

      //todo get entries per checkup

      let result = get.data;
      if (!Array.isArray(result)) {
        result = [result];
      }


      if (this.checkupListType === 'segment') {
        // Put segment analytics in correct format
        const newItems = [];
        for (let item of result) {
          newItems.push({
            checkup: {
              title: this.swarm.title + ' @ ' + moment(item.created_at).format('DD/MM/YYYY'),
            },
            end: moment(item.created_at).toISOString(),
            swarmcomputeds: [item]
          });
        }
        result = newItems;
      }

      this.checkups = result;


      for (const checkup of this.checkups) {
        checkup.activeSearch = ""
        checkup.isOpen = false

        for (const computed of checkup.swarmcomputeds) {
          computed.checkupentry = {
            end: checkup.end
          }
        }
      }

      if (this.checkups[0]) {
        this.checkups[0].isOpen = true;

        this.setActiveCheckup(this.checkups[0].swarmcomputeds[0]);
      } else {
        this.checkups = [];
      }
      this.checkupsloaded = true;
    },
    setActiveCheckup(checkup) {
      checkup ? (checkup.included = true) : false;
    },
  },
};
</script>
<style lang="scss">
.checkups-list.checkboxes {
  .checkup-item:not(:first-child) {
    position: relative;

    &:before {
      position: absolute;
      display: block;
      bottom: calc(50% + 9px);
      height: calc(100% - 16px);
      width: 1px;
      left: 22px;
      content: "";
      background-color: #3d4152;
    }
  }
}

.checkup-subgroup {
  @media all and (min-width: 768px) {
    max-height: 20vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>