<template>
  <form @submit.prevent="submitSegment" class="modal-content">
    <div class="modal-header p-25">
      <h5 class="modal-title col">
        <input
          :placeholder="$t('message.enterATitle')"
          v-model="title"
          required
          type="text"
          class="input-xl w-100"
        />
      </h5>
      <button
        tabindex="-1"
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body pb-0">
      <div class="operator mb-3">
        <span class="text-sentence text-nowrap">{{ $t("message.users_match") }}&nbsp;</span>
        <select
          @change="handleChange($event)"
          v-model="filterOp"
          name=""
          id=""
          class="d-inline text-capitalize"
        >
          <option value="all">{{ $t("message.all") }}</option>
          <option value="any">{{ $t("message.any") }}</option>
          <!--          <option value="none">{{ $t('message.none') }}</option>--></select
        >&nbsp;
        <span class="text-sentence">{{ $t("message.of_the_following_conditions") }}:</span>
      </div>
      <div
        class="filter-row mb-3"
        v-for="(filter, index) in filters"
        v-bind:key="filter"
      >
        <div class="d-flex align-items-center">
          <div class="row filter-inputs flex-grow-1">
            <div class="col-4 pe-1 flex-grow-0">
              <select
                @change="handleChange($event, filter)"
                v-model="filter.field"
                class="text-capitalize w-100 flex-grow-1"
                name="field"
                id="field"
              >
                <option disabled value="">Field</option>
                <option
                  v-for="field in filter_fields"
                  v-bind:key="field"
                  :value="field.field"
                >
                  {{ field.label }}
                </option>
              </select>
            </div>
            <div class="col-4 px-1 flex-grow-0">
              <select
                @change="handleChange($event, filter)"
                v-model="filter.operator"
                class="w-100 flex-grow-1"
                name="operator"
                id="operator"
              >
                <option disabled value="">{{ $t("message.operator") }}</option>
                <option
                  :value="key"
                  :key="key"
                  v-for="(label, key) in getOperators(filter.field)"
                >
                  {{ label }}
                </option>
                <!--                <option value="eq">Equals</option>-->
                <!--                <option value="ne">Does not equal</option>-->
                <!--                <option value="contains">Contains</option>-->
                <!--                <option value="ncontains">Does not contain</option>-->
                <!--                <option value="gt">Greater than</option>-->
                <!--                <option value="gte">Greater than or equal to</option>-->
                <!--                <option value="lt">Lesser than</option>-->
                <!--                <option value="lte">Lesser than or equal to</option>-->
              </select>
            </div>
            <div class="col-4 ps-1 flex-grow-0">
              <select
                v-if="getFieldType(filter.field) === 'select'"
                @change="handleChange($event, filter)"
                v-model="filter.value"
                class="w-100 flex-grow-1 text-capitalize"
                name="value"
                id="value"
              >
                <option disabled value="">{{ $t("message.value") }}</option>
                <option
                  v-for="option in getFieldOptions(filter.field)"
                  v-bind:key="option"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
              <input
                v-else-if="getFieldType(filter.field) === 'text'"
                @change="handleChange($event, filter)"
                name="value"
                id="value"
                class="w-100"
                v-model="filter.value"
                type="text"
              />
              <input
                v-else-if="getFieldType(filter.field) === 'date'"
                @change="handleChange($event, filter)"
                name="value"
                id="value"
                class="w-100"
                v-model="filter.value"
                type="date"
              />
            </div>
          </div>

          <div class="filter-actions flex-shrink-0">
            <button
              @click="removeFilter(index)"
              v-if="filters.length > 1"
              type="button"
              class="btn-close ms-3 p-0 flex-shrink-0"
            ></button>
            <span
              v-else
              type="button"
              class="ms-3 flex-shrink-0 spacer"
              style="width: 15px"
            ></span>
          </div>
        </div>
      </div>

      <div
        class="
          add-filter
          bgc-theme
          c-200
          py-2
          px-3
          mb-3
          border-top border-bottom
        "
      >
        <button
          class="btn btn-secondary btn-sm"
          @click.prevent.stop="addFilter"
        >
          <img
            class="hide-dark"
            src="../../../assets/icons/icon-navigation-add-cropped.svg"
            alt=""
          />
          <img
            class="hide-light"
            src="../../../assets/icons/icon-navigation-add-cropped-light.svg"
            alt=""
          />
          <span class="text-sentence">{{ $t("message.filter") }}</span>
        </button>
      </div>

      <label for="" class="add-manager text-sentence"> {{ $t('message.add_a_manager') }} </label>
      <div class="selected-blocks">
        <div
          class="d-flex justify-content-between blocks py-2 border-top"
          v-for="(user, index) in managers"
          v-bind:key="user"
        >
          <div
            style="height: 2rem; width: 2rem"
            class="
              me-3
              flex-grow-0 flex-shrink-0
              bgc-groen
              c-200
              block-icon
              rounded-3
              d-flex
              justify-content-center
              align-content-center
            "
          >
            <img
              src="../../../assets/icons/Icon-block-manager-24px.svg"
              alt=""
            />
          </div>
          <div class="block-info flex-grow-1">
            <h5 class="mb-0">{{ user.firstname }} {{ user.lastname }}</h5>
            <div class="text-sm"><span class="text-sentence">{{ $t("message.manages") }} {{ user.swarms.length }} {{ $t("message.teams") }}</span></div>
          </div>
          <img
            class="cursor-pointer"
            @click="managers.splice(index, 1)"
            src="../../../assets/icons/icon-navigation-close.svg"
            alt=""
          />
        </div>
      </div>
      <div
        class="add-filter bgc-theme c-200 py-2 px-3 border-top border-bottom"
      >
        <div class="d-flex justify-content-between">
          <select-search
            class="me-2 flex-grow-1"
            :value="'id'"
            :label="['lastname', 'firstname']"
            :ajax="true"
            :query="'/users'"
            @selectOption="addManager"
            :placeholder="$t('message.search_users')"
          ></select-search>
        </div>
      </div>
      <div
        class="
          segment-preview
          mt-4
          text-end
          d-flex
          justify-content-end
          align-items-stretch
        "
      >
        <span class="me-2 text-sentence">{{ $t('message.segment_contains') }}</span>
        <img src="../../../assets/icons/Icon-tree-user-profile.svg" alt="" />
        <span v-if="countLoading"
          ><div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{ $t("message.loading") }}</span>
          </div></span
        ><span v-else>{{ userCount }}</span>
        <span class="spacer border-end mx-2">&nbsp;</span>
        <img src="../../../assets/icons/Icon-tree-manager-profile.svg" alt="" />
        {{ managers.length }}
      </div>
      <div
        class="w-100 text-end"
        v-if="filters && userCount < publicSettings.minusers"
      >
        <small class="text-danger"><span class="text-sentence">{{
          $t("message.x_users_in_segment", { amt: publicSettings.minusers })
        }}</span></small>
      </div>
    </div>
    <div
      class="modal-footer p-25 pt-0 d-flex justify-content-between border-top-0"
    >
      <div class="colorpicker">
        <label for="" class="text-sentence">{{ $t('message.pick_a_color') }}</label>
        <div class="colors d-flex">
          <!--          {{segmentcolor}}-->
          <span
            v-for="color in colors"
            v-bind:key="color"
            class="mb-0 me-1 flex-grow-0 color c-50"
            :class="'bc-' + color"
          >
            <input
              :id="'c-' + color"
              type="radio"
              class="d-none"
              :value="color"
              name="color"
              v-model="segmentcolor"
            />
            <label
              class="c-200 color-label text-sentence"
              :class="'bgc-' + color"
              :for="'c-' + color"
              style="height: 1.6rem; width: 1.6rem; border-radius: 4px"
              :style="'border-color:' + this.getColorCode(color)"
            >
            </label>
          </span>
        </div>
      </div>
      <!--      {{filters}}-->
      <!-- <button
        v-if="!loading"
        :class="
          (publicSettings && userCount < publicSettings.minusers) ||
          filters.length < 1
            ? 'disabled'
            : ''
        "
        type="button"
        class="btn btn-danger"
        @click="deleteTeamSegment"
      >
        {{ $t("message.delete") }}
      </button> -->
      <button
        v-if="!loading"
        :class="
          (publicSettings && userCount < publicSettings.minusers) ||
          filters.length < 1
            ? 'disabled'
            : ''
        "
        type="submit"
        class="btn btn-primary"
      >
        <span class="text-sentence">{{ $t("message.save") }}</span>
      </button>
      <button v-else class="btn disabled d-flex" type="button" disabled>
        <div
          style="height: 12px; width: 12px"
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden">{{ $t("message.loading") }}</span>
        </div>
        <span class="ms-2 text-sentence">{{ $t("message.submitting") }}</span>
      </button>
    </div>
  </form>
</template>
<script>
import SelectSearch from "@/views/components/simple/SelectSearch";
import axios from "axios";

export default {
  components: { SelectSearch },
  props: ["segment"],
  computed: {
    publicSettings() {
      return this.$store.getters.getPublicSettings;
    },
    managerIds() {
      return this.managers.map((user) => {
        return user.id;
      });
    },
    filter_fields() {
      return [
        {
          field: "gender",
          label: this.$t("message.gender"),
          type: "select",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
          },
          options: this.findOptions("gender")/*[
            { value: "m", label: this.$t("message.gender_m") },
            { value: "f", label: this.$t("message.gender_f") },
            { value: "x", label: this.$t("message.gender_x") },
          ]*/,
        },
        {
          field: "city",
          label: this.$t("message.city"),
          type: "text",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
            contains: "Contains",
            ncontains: "Does not contain",
          },
          options: [{ value: "", label: "" }],
        },
        {
          field: "country",
          label: this.$t("message.country"),
          type: "select",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
            // contains: 'Contains',
            // ncontains: 'Does not contain',
          },
          options: this.countries.map((country) => {
            return {
              value: country,
              label: this.$t("message.country_" + country),
            };
          }),
        },
        {
          field: "birthdate",
          label: this.$t("message.birthdate"),
          type: "date",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
            gt: "After",
            gte: "After or on",
            lt: "Before",
            lte: "Before or on",
          },
        },
        {
          field: "employmentstart",
          label: this.$t("message.employmentstart"),
          type: "date",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
            gt: "After",
            gte: "After or on",
            lt: "Before",
            lte: "Before or on",
          },
        },
        {
          field: "member",
          label: this.$t("message.team"),
          type: "select",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
          },
          options: this.teams,
        },
        {
          field: "usertags",
          label: this.$t("message.tags"),
          type: "select",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
          },
          options: this.usertags,
        },
        {
          field: "education",
          label: this.$t("message.education"),
          type: "select",
          operators: {
            eq: "Equals",
            ne: "Does not equal",
            gt: "Greater than",
            gte: "Greater than or equal to",
            lt: "Lesser than",
            lte: "Lesser than or equal to",
          },
          options: this.findOptions("education"),
        },
        // {
        //   field: 'children',
        //   label: this.$t('message.amount_of_children'),
        //   type: 'text',
        // },
      ];
    },
  },
  methods: {
    getOperators(field) {
      const filter = this.filter_fields.find(
        (filter) => filter.field === field
      );
      return filter ? filter.operators : {};
    },
    findOptions(key) {
      if (this.fieldOptions && this.fieldOptions.listoptions) {
        const field = this.fieldOptions.listoptions.filter((opts) => {
          return opts.key === key;
        })[0];
        // console.log('field',field)
        return field.options.map((fieldoption) => {
          return {
            value: fieldoption.value,
            label:
              this.fieldOptions.localized[this.$i18n.locale].listtranslations[
                fieldoption.key
              ] ||
              this.fieldOptions.localized["en"].listtranslations[
                fieldoption.key
              ] ||
              fieldoption.key,
          };
        });
      } else {
        return [];
      }
    },

    // handleSelect(user) {
    //   // console.log('selected',user)
    //   this.selectedManager = user
    // },
    handleChange(e, filter) {
      //if field: filter.value = '' && operator.value = ''
      //console.log('change to filters', e, filter)
      if (e.target.id === "field") {
        filter.value = "";
        filter.operator = "";
      }
      // console.log('filters changed', e)
      if (filter) {
        if (filter.value && filter.operator && filter.field) {
          this.getMatchedCount();
        }
      } else {
        this.getMatchedCount();
      }
    },

    async getCountries() {
      const f = await axios.get("/countries.json");
      this.countries = f.data;
    },
    async getMatchedCount() {
      this.countLoading = true;
      const filter = {
        operator: this.filterOp,
        rules: this.filters,
      };
      try {
        const post = await this.axios.post("/swarms/segmentusercount", filter);
        this.userCount = post.data;
      } catch (e) {
        //  console.log('error counting')
      }

      this.countLoading = false;
    },
    async submitSegment() {
      this.loading = true;
      const segment = {
        issegment: true, //backend hard setten
        title: this.title,
        overlords: this.managerIds,
        filters: {
          operator: this.filterOp,
          rules: this.filters,
        },
        color: this.segmentcolor,
      };
      if (this.segment && this.segment.id) {
        const put = await this.axios.put("/swarms/" + this.segment.id, segment);
        this.$emit("submitSegment", put.data);
      } else {
        const post = await this.axios.post("/swarms", segment);
        this.$emit("submitSegment", post.data);
      }
      this.loading = false;
      this.$store.dispatch("closeModal");
    },
    // async getManagers() {
    //   const get = await this.axios.get('/users?managesegments=true&id_nin=' + this.managerIds)
    //   this.managerList = get.data
    // },
    addManager(user) {
      // console.log('add', user)
      if (user && !this.managerIds.includes(user.id)) {
        this.managers.push(user);
        // user = null
      }
      // this.managerPopupShown = true;
    },
    addFilter() {
      this.filters.push({
        field: "",
        operator: "",
        value: "",
      });
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
      this.getMatchedCount();
    },
    async getTeamOptions() {
      const get = await this.axios.get("/swarms?issegment_ne=true&visibility=true&_limit=-1");
      this.teams = get.data.map((team) => {
        return {
          label: team.title,
          value: team.id,
        };
      });
      this.filter_fields.forEach((field) => {
        if (field.field === "member") {
          field.options = this.teams;
        }
      });
    },
    async getTagsOptions() {
      const get = await this.axios.get("/usertags?isHidden_ne=true");
      this.usertags = get.data.map((team) => {
        return {
          label: team.title,
          value: team.id,
        };
      });
      this.filter_fields.forEach((field) => {
        if (field.field === "usertags") {
          field.options = this.usertags;
        }
      });
    },
    getFieldType(field) {
      let found = this.filter_fields.filter((f) => {
        return f.field === field;
      })[0];
      if (found) {
        return found.type;
      } else {
        return "";
      }
    },
    getFieldOptions(field) {
      let found = this.filter_fields.filter((f) => {
        return f.field === field;
      })[0];
      if (found) {
        return found.options;
      } else {
        return "";
      }
    },
    async loadSegmentManagers() {
      const get = await this.axios.get(
        "/users?swarms_contains=" + this.segment.id
      );
      this.managers = get.data;
    },
    loadFilters() {
      this.filterOp = this.segment.filters.operator;
      this.filters = this.segment.filters.rules;
      this.getMatchedCount();
    },
    loadSegment() {
      // console.log('loading segment', this.segment)
      this.title = this.segment.title;
      this.segmentcolor = this.segment.color;
      if (this.segment.filters) {
        this.loadFilters();
      }
      this.loadSegmentManagers();
    },
    async getListOptions() {
      const get = await this.axios.get("/settings/fieldoptions");
      const options = get.data;
      //console.log('options', options)
      this.fieldOptions = options;
    },
  },
  created() {
    this.getListOptions();
    this.addFilter();
    // this.getManagers()
    this.getTeamOptions();
    this.getTagsOptions();
    if (this.segment && this.segment.id) {
      this.loadSegment();
    }
  },
  mounted() {
    this.getCountries();
  },
  data() {
    return {
      countries: [],
      fieldOptions: null,
      loading: false,
      countLoading: false,
      // selectedManager: null,
      userCount: 0,
      filterOp: "all",
      title: "",
      segmentcolor: null,
      managerPopupShown: false,
      filters: [],
      managers: [],
      managerList: [],
      teams: [],
      usertags: [],
      filterable_fields: [
        "usertags",
        "city",
        "startemployment",
        "endemployment",
        "gender",
        "birthdate",
        "team",
        "education",
      ],
      // filter_fields: [
      //   {
      //     field: 'firstname',
      //     label: this.$t('message.firstname'),
      //     type: 'text',
      //     options: [
      //       {value: '', label: ''}
      //     ]
      //   },
      //   {
      //     field: 'lastname',
      //     label: this.$t('message.lastname'),
      //     type: 'text',
      //     options: [
      //       {value: '', label: ''}
      //     ]
      //   },
      //   {
      //     field: 'gender',
      //     label: this.$t('message.gender'),
      //     type: 'select',
      //     options: [
      //       {value: 'm', label: this.$t('message.gender_m')},
      //       {value: 'f', label: this.$t('message.gender_f')},
      //       {value: 'x', label: this.$t('message.gender_x')},
      //     ]
      //   },
      //   {
      //     field: 'birthdate',
      //     label: this.$t('message.birthdate'),
      //     type: 'date',
      //   },
      //   {
      //     field: 'team',
      //     label: this.$t('message.team'),
      //     type: 'select',
      //     options: []
      //   },
      //   {
      //     field: 'education',
      //     label: this.$t('message.education'),
      //     type: 'select',
      //     options: [
      //       {value: '0', label: this.$t('message.none')},
      //       {value: '1', label: this.$t('message.primary')},
      //       {value: '2', label: this.$t('message.secondary')},
      //       {value: '3', label: this.$t('message.bachelor')},
      //       {value: '4', label: this.$t('message.master')},
      //       {value: '5', label: this.$t('message.phd')},
      //     ]
      //   },
      //   {
      //     field: 'children',
      //     label: this.$t('message.amount_of_children'),
      //     type: 'text',
      //   },
      // ]
    };
  },
};
</script>

<style>
.btn.btn-danger {
  border-color: #dc3545;
}
</style>